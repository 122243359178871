import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SeoAgencyComponent } from './components/pages/seo-agency/seo-agency.component';
import { AiStartupComponent } from './components/pages/ai-startup/ai-startup.component';
import { MachineLearningComponent } from './components/pages/machine-learning/machine-learning.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { CaseStudyDetailsComponent } from './components/pages/case-study-details/case-study-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';

const routes: Routes = [
  { path: 'deleted-1', component: SeoAgencyComponent },
  { path: '', component: AiStartupComponent, title: 'Home - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'machine-learning', component: MachineLearningComponent },
  { path: 'about', component: AboutComponent, title: 'About - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'team', component: TeamComponent },
  { path: 'ipromise-mobileapps', component: PricingComponent, title: 'Mobile Apps | Portfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'ipromise-socialmedia', component: FaqComponent, title: 'Social Media | Portfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'ipromise-ecommerce', component: TestimonialsComponent, title: 'E-Commerce | Portfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'ipromise-website', component: CaseStudyComponent, title: 'Website | Portfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'ipromise-eGovernanceConsultancy', component: TermsConditionsComponent, title: 'eGovernance | Portfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'ipromise-ai', component: PrivacyPolicyComponent, title: 'AI | Pottfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'case-study-details1', component: CaseStudyDetailsComponent },
  { path: 'error1', component: ErrorComponent },
  { path: 'sign-in1', component: SignInComponent },
  { path: 'sign-up1', component: SignUpComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'services', component: ServicesComponent, title: 'Services - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'services-details1', component: ServicesDetailsComponent },
  { path: 'blog1', component: BlogComponent },
  { path: 'blog-details1', component: BlogDetailsComponent },
  { path: 'contact', component: ContactComponent, title: 'Contact - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },


  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }