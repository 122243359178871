<form class="seo-form-option" [formGroup]="ContactFrm" (ngSubmit)="feedback()">
    <div class="row">
        <div class="col-lg-4 col-sm-6 col-md-4">
            <div class="form-group">
                <i class='fa fa-user'></i>
                <input class="form-control form-border" formControlName="name" name="name" type="text" placeholder="Enter Your Name...">
            </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-4">
            <div class="form-group">
                <i class='fa fa-phone'></i>
                <input class="form-control" type="text" formControlName="phone_number" name="phone_number"  placeholder="Enter Contact Number">
            </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-4 offset-sm-3 offset-md-0 offset-lg-0">
            <button type="submit" class="default-btn">
               Send
                <i class='bx bx-plus'></i>
            </button>
        </div>
    </div>
</form>