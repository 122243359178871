<app-navbar-style-three></app-navbar-style-three>

<div class="banner-area-three">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="banner-content-three">
                        <span>Learning Management</span>
                        <h1>Machine Learning & Cognitive Solution</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor avida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div class="banner-three-btn">
                            <a routerLink="/contact" class="explore-btn">Explore Bootcamp <i class='bx bx-plus'></i></a>
                            <a href="https://www.youtube.com/watch?v=07d2dXHYb94" class="play-two-btn popup-btn">Play video <i class='flaticon-forward'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-img-3">
        <img src="assets/img/home-three/home-three-img.png" alt="Images">
    </div>

    <div class="banner-three-shape">
        <div class="shape1"><img src="assets/img/shape/shape16.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape15.png" alt="Images"></div>
    </div>
</div>

<div class="brand-logo-area">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-area-three pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Our Services</span>
            <h2>We Help Your Business Grow</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>                         
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-item-two">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon10.png" alt="assets"></a>
                    <h3><a routerLink="/services-details">Marketing Analytics</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-item-two">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon11.png" alt="assets"></a>
                    <h3><a routerLink="/services-details">Startup Applications</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-sm-3 offset-md-3 offset-lg-0">
                <div class="services-item-two">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon12.png" alt="assets"></a>
                    <h3><a routerLink="/services-details">Technology</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area-two pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="assets/img/about/about-img3.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <span class="sp-before sp-after">About Us</span>
                            <h2>Grow Your Business With Our Agency</h2>
                        </div>
                        <h3>We have 30 yearsOur strategy includes consistently evolving, to ensure we’re producing exceptional SEO for business.</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae tempor nisl. Etiam magna augue, tempus eget suscipit et, maximus hendrerit nunc. Nunc elementum non ipsum vitae molestie. Donec vestibulum lobortis congue. Mauris sed tincidunt leo. Suspendisse accumsan nibh et diam porta ultrices.</p>      
                        <div class="about-btn-two">
                            <a routerLink="/about" class="about-more-btn">About Us <i class='bx bx-plus'></i></a>
                            <a routerLink="/contact" class="contact-us-btn">Contact Us <i class='bx bx-plus'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="schedule-area">
    <div class="container-fluid">
        <div class="schedule-width ptb-100">
            <div class="schedule-content">
                <h2>Check Full Schedule for In-person Data Science Bootcamps</h2>
                <a routerLink="/contact" class="default-btn-two">Join The Bootcamp <i class='bx bx-plus'></i></a>
                <div class="schedule-content-shape">
                    <div class="shape1"><img src="assets/img/shape/shape15.png" alt="Images"></div>
                    <div class="shape2"><img src="assets/img/shape/shape16.png" alt="Images"></div>
                </div>
            </div>
            <div class="schedule-shape">
                <img src="assets/img/shape/schedule-shape.png" alt="Images">
            </div>
        </div>
    </div>
</div>

<div class="serve-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="serve-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Why We’re</span>
                        <h2 class="h2-color2">We Serve As Possible That You Expected</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tetur adipisicing saliqua.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Experts at Teaching Online</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Proven Career Outcomes</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Experts at Teaching System</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>04</span>
                                <p>Leadership Learning system</p>
                            </div>
                        </div>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tetur adipisicing saliqua. </p>
                    <a routerLink="/contact" class="default-btn-two">Apply for position <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="serve-img">
                    <img src="assets/img/serve-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="courses-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Our Courses</span>
            <h2 class="h2-color2">Online Data Science Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="assets/img/courses/courses-img1.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">Free</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor1.png" alt="Images">
                        <h3 class="name"><a routerLink="/">David McLean</a> </h3>
                    </div>
                    <h3><a routerLink="/">Introduction to Linear Models and Matrix Algebra</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 8 Weeks Long</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="assets/img/courses/courses-img2.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">$80</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor2.png" alt="Images">
                        <h3 class="name"><a routerLink="/">Jems ward</a></h3>
                    </div>
                    <h3><a routerLink="/"> Introduction to Quantitative Methods</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 9 Weeks Long</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="assets/img/courses/courses-img3.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">$78</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor3.png" alt="Images">
                        <h3 class="name"><a routerLink="/">Juhan Luis</a></h3>   
                    </div>
                    <h3><a routerLink="/">Data Science Inference and Modeling</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 5 Weeks Long</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="assets/img/courses/courses-img4.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">Free</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor4.png" alt="Images">
                        <h3 class="name"><a routerLink="/"> Miron Don</a></h3>
                    </div>
                    <h3><a routerLink="/">Introduction to Linear Data Visualization</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 4 Weeks Long</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="testimonial-area-two pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="testimonial-content">
                    <div class="testimonial-content1">
                        <div class="testimonial-text">
                            <h3>10 +</h3>
                            <span>ON THE MARKET</span>
                        </div>
                    </div>

                    <div class="testimonial-content2">
                        <div class="testimonial-text">
                            <h3>10,000+</h3>
                            <span>LEARNERS</span>
                        </div>
                    </div>

                    <div class="testimonial-content3">
                        <div class="testimonial-text">
                            <h3>196+</h3>
                            <span>COUNTRIES</span>
                        </div>
                    </div>

                    <div class="testimonial-content4">
                        <div class="testimonial-text">
                            <h3>125+</h3>
                            <span>COURSE</span>
                        </div>
                    </div>

                    <div class="testimonial-shape1">
                        <img src="assets/img/shape/shape15.png" alt="Images">
                    </div>
                    
                    <div class="testimonial-shape2">
                        <img src="assets/img/shape/shape16.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="testimonial-slider-area">
                    <div class="testimonial-title-2">
                        <h2>Words From Customers</h2>
                    </div>

                    <div class="testimonial-slider-two owl-carousel owl-theme">
                        <div class="testimonial-item-2">
                            <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family.</p>
                            <div class="content">
                                <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                                <div class="content-title">
                                    <h3>David McLean</h3>
                                    <span>CEO & Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-item-2">
                            <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family.</p>
                            <div class="content">
                                <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                                <div class="content-title">
                                    <h3>Jermin Jekson</h3>
                                    <span>Marketing Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-item-2">
                            <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family.</p>
                            <div class="content">
                                <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                                <div class="content-title">
                                    <h3>Julfiker Jeain</h3>
                                    <span>CEO At Ostino</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-area blog-bg2 pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-title">
                    <div class="section-title">
                        <span class="sp-after">Our Blogs</span>
                        <h2 class="h2-color-2">Latest Valuable Insights</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="blog-btn">
                    <a routerLink="/blog" class="default-btn-two">See all news <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog4.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details" class="title-color">The Home of Technology is in Front of You</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog5.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 10 July 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details" class="title-color">SEO Best Practices Mobile Friendliness</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog6.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 19 June 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details" class="title-color">15 SEO Practices Website Architecture</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="project-area">
    <div class="container">
        <div class="project-content">
            <h2>Already Interested! Do You Have Any Project to Working With?</h2>
            <a routerLink="/contact" class="project-btn">Get started <i class='bx bx-plus'></i></a>
        </div>
    </div>

    <div class="project-shape">
        <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
        <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>