import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { AboutComponent } from '../about/about.component';
import { AiStartupComponent } from '../ai-startup/ai-startup.component';
import { BlogDetailsComponent } from '../blog-details/blog-details.component';
import { BlogComponent } from '../blog/blog.component';
import { CaseStudyDetailsComponent } from '../case-study-details/case-study-details.component';
import { CaseStudyComponent } from '../case-study/case-study.component';
import { ComingSoonComponent } from '../coming-soon/coming-soon.component';
import { ContactComponent } from '../contact/contact.component';
import { ErrorComponent } from '../error/error.component';
import { FaqComponent } from '../faq/faq.component';
import { MachineLearningComponent } from '../machine-learning/machine-learning.component';
import { PricingComponent } from '../pricing/pricing.component';
import { SeoAgencyComponent } from '../seo-agency/seo-agency.component';
import { ServicesDetailsComponent } from '../services-details/services-details.component';
import { ServicesComponent } from '../services/services.component';
import { SignInComponent } from '../sign-in/sign-in.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { TeamComponent } from '../team/team.component';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { TestimonialsComponent } from '../testimonials/testimonials.component';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

export const routes: Routes = [
  { path: 'deleted-1', component: SeoAgencyComponent },
  { path: '', component: AiStartupComponent, title: 'Home - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'machine-learning', component: MachineLearningComponent },
  { path: 'about', component: AboutComponent, title: 'About - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'team', component: TeamComponent },
  { path: 'ipromise-mobileapps', component: PricingComponent },
  { path: 'ipromise-socialmedia', component: FaqComponent, title: 'Social Media | Portfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'ipromise-ecommerce', component: TestimonialsComponent, title: 'E-Commerce | Portfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'ipromise-website', component: CaseStudyComponent, title: 'Website | Portfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'ipromise-eGovernanceConsultancy', component: TermsConditionsComponent, title: 'eGovernance | Portfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'ipromise-ai', component: PrivacyPolicyComponent, title: 'AI | Pottfolio - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'case-study-details1', component: CaseStudyDetailsComponent },
  { path: 'error1', component: ErrorComponent },
  { path: 'sign-in1', component: SignInComponent },
  { path: 'sign-up1', component: SignUpComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'services', component: ServicesComponent, title: 'Services - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },
  { path: 'services-details1', component: ServicesDetailsComponent },
  { path: 'blog1', component: BlogComponent },
  { path: 'blog-details1', component: BlogDetailsComponent },
  { path: 'contact', component: ContactComponent, title: 'Contact - IPromise Solutions Private Limited, Thiruvananthapuram, Kerala,India' },


  { path: '**', component: ErrorComponent }
];
